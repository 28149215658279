<template>
    <div class="room">

        <h3 class="room-title">
            <div class="container"><span class="margin-title">{{room.nome}}</span></div>
        </h3>
        <div class="container">
        <img class="room-image" :src="room.imagem" :alt="room.alt">
        <p v-if="room.subtitulo" class="room-subtitle">{{room.subtitulo}}</p>

        <p v-if="room.texto_titulo" class="room-texto room-texto-titulo">{{room.texto_titulo}}</p>
        <p v-if="room.texto" class="room-texto">{{room.texto}}</p>
        <p v-if="room.texto_assinatura" class="room-texto room-texto-assinatura">{{room.texto_assinatura}}</p>

        <Work v-for="work in room.works" :key="work.id" :work="work" />
        
        <p v-if="room.footer" class="room-subtitle">{{room.footer}}</p>  
        <div class="bottom-space"></div>
        </div>   
    </div>
</template>
<script>
import Work from './Work';

export default {
    props:['room'],
    components:{
        Work
    }
}
</script>

<style scoped>

.room-title{
    background-color:#a41d41;
    color:#fff;
    padding-top: 14px;
    line-height: 24px;
    font-size: 16px;
    font-weight:normal;
    height:140px;
}
@media screen and (min-width: 320px){
    .room-title{
        height:220px;
    }
}
.room-image{
    margin-top: -180px;
    width:100%;
    height: auto;
}
@media screen and (min-width: 880px){
    .room-image{
        margin-top: -180px;
    }
}
.margin-title{
    margin: 0px 25px;   
}
.room-subtitle{
    font-size:14px;
    color:#767676;
    line-height: 20px;
    margin: 24px 25px;
}
.room-texto{
    font-size:14px;
    max-width: 770px;
    color:#767676;
    line-height: 24px;
    margin: 8px 45px;
}
.room-texto-titulo{
    font-weight: bold;
    font-style: italic;
    margin: 34px 25px 8px 25px;
}
.room-texto-assinatura{
    font-weight: bold;
    font-style: italic;
    margin: 8px 25px;
}
@media screen and (min-width: 370px){
    .room-title{
        height:160px;
    }
}
@media screen and (min-width: 440px){
    .room-title{
        height:200px;
    }
}
@media screen and (min-width: 880px){
    .room-image{
       width: 880px; 
    }
}
@media screen and (min-width: 1290px){
    .margin-title{
        margin:24px 25px; 
    }
    .room-subtitle{
        margin-left: 0px;
    }
    .room-texto{
        margin-left: 20px;
    }
    .room-texto-titulo{
        margin-left: 0px;
    }
    .room-texto-assinatura{
        margin-left: 0px;
    }
}
.bottom-space{
    padding-bottom: 40px;
}  
</style>
<template>
    <div class="work">
        <div class="work-description"> 
            <div class="description">
                <b v-if="lang=='pt_br'">Artista:</b>
                <b v-if="lang=='en'">Artist:</b> 
                {{work.artista}}.</div>
            <div class="description">
                <b v-if="lang=='pt_br'">Obra:</b> 
                <b v-if="lang=='en'">Artwork:</b> 
                {{work.obra}}, {{work.ano}}. {{work.tecnica}} ({{work.dimensao}}).</div>   
        </div>
        <div v-if="!work.video" class="work-image" :class="work.tipo">
            
            <img :src="work.imagem" :alt="work.alt" />
        </div>
        <div v-if="work.video" class="work-video">
            <div class="video-responsive" v-html="work.video"></div>
        </div>
    </div>
</template>
<script>
export default{
    props:['work'],
    data(){
        return {lang:localStorage.getItem('lang')}
    }
}
</script>
<style scoped>
    .work{
        margin: 40px 24px 0px;
    }
    @media screen and (min-width: 1290px) {
        .work{
        margin-left:0px;
        }
    }
    .work-image img,
    .vertical img,
    .horizontal img,
    .vertical.grande img,
    .vertical.media img,
    .vertical.pequena img,
    .quadrado img,
    .quadrado.media img,
    .quadrado.pequena img,
    .horizontal.grande img,
    .horizontal.media img,
    .horizontal.pequena img{
        width:100%;
        height: auto;
    }
    
    @media screen and (min-width: 650px) {
    
    .work-image img{
        width:auto;
        height: 530px;
    }

    .horizontal img{
        width: auto;
        height:580px;
    }
    .vertical img{
        width: 580px;
        height: auto;
    }
    .vertical.grande img{
        width:450px;
        
    }
    .vertical.media img{
        width:300px;
    }
    .vertical.pequena img{
        width:250px;
    }


    .quadrado img{
        width:450px;
        height: auto;
    }
    .quadrado.media img{
        width:400px;
        height: auto
    }
    .quadrado.pequena img{
        width:350px;
        height: auto
    }


    .horizontal.grande img{
        width:auto;
        height: 450px;
    }
    .horizontal.media img{
        width:auto;
        height: 400px;
    }

    .horizontal.pequena img{
        width:auto;
        height:300px;
    }


    .vertical.grande img{
        width:450px;
        height: auto;
    }
    .vertical.media img{
        width:300px;
        height: auto;
    }
    .vertical.pequena img{
        width:250px;
        height: auto;
    }

    }

    .work-description{
        font-size:14px;
        color:#767676;
        line-height: 20px;
        margin: 0px 0px 2px;
    }
    work-video{
        width:100%;
    }
    @media screen and (min-width: 880px) {
        .work-video{
        width:880px;
        }
    }
    .video-reponsive{
        width: 100%;
    }
</style>
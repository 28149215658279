<template>
    <div class="footer">
    <div class="section-footer">
      <div class="container">
      <div class="footer-menu">
        <router-link v-for="(item_menu, item_key) in menu" :to="item_menu.link" :key="item_key">{{item_menu.name}}</router-link> 
      </div>
      <div class="footer-right" @click="goToTop()">
          {{ (lang != 'en') ? 'Voltar ao Topo' :'Back to top' }}
      </div>
      <div class="clear"></div>
      <div class="contato-footer">Contato e sugestões:<br><b><a href="mailto:expopresencanaausencia@gmail.com">expopresencanaausencia@gmail.com</a></b></div>
      </div>
    </div>


    <div class="section-footer-logo">
      <div class="container">
      <img class="footer-logo" src="../assets/logo-presenca-na-ausencia.png">
      <img class="footer-parceiro" src="/img/universidade-feevale.png">
      <img class="footer-parceiro" src="/img/espaco-cultural-feevale.png">
      <img class="footer-parceiro" src="/img/diversidade.png">
      <p>&copy; 2020 Presença na Ausência, Todos os direitos reservados.</p>
      </div>
    </div>
    </div>
</template>
<script>
export default{
    props:['lang','menu'],
    methods:{
      goToTop(){
        window.scrollTo(0,0);
      }
    }
}
</script>

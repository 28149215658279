import Vue from 'vue'
import VueRouter from 'vue-router'
import Exposicao from '../views/Exposicao.vue'
import Artistas from '../views/Artistas.vue'
import Material from '../views/Material.vue'
import Equipe from '../views/Equipe.vue'
import menu from '../content/menu';

Vue.use(VueRouter)
let lang = localStorage.getItem('lang') == 'en' ? 'en' : 'pt_br';
const props = {
  lang: lang,
  menu: (lang == 'en') ? menu.en : menu.pt_br
}
const routes = [
  {
    path: '/',
    name: 'Presença na ausência',
    component: Exposicao,
    props:props
  },
  {
    path: '/exposicao',
    name: 'Exposição',
    component: Exposicao,
    props:props
  },
  {
    path:'/artistas',
    name:'Artistas',
    component:Artistas,
    props:props
  },
  {
    path:'/educativo',
    name:"Educativo",
    component: Material,
    props:props
  },
  {
    path:'/equipe',
    name:"Equipe",
    component: Equipe,
    props:props
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

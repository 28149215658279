<template>
  <div class="artistas">
    <Header :lang="lang" :menu="menu" />
    <div class="container">
    <p class="title">{{intro}}
    </p>
    <div class="divisor"></div>
    <Artista v-for="(artista, key) in artistas" :key="key" :artista="artista" />
    </div>
    <Footer :lang="lang" :menu="menu" />
  </div>
</template>

<script>
import Header from '../components/Header';
import Footer from '../components/Footer';
import Artista from '../components/Artista';
import artistas_en from '../content/artistas_en';
import artistas_pt_br from '../content/artistas_pt_br';

export default{
  props:['menu','lang'],
  components:{
    Header,
    Footer,
    Artista
  },
  data: function(){
    var content = {}
        if(localStorage.getItem('lang')=='en'){
            content = artistas_en;
        }else{
            content = artistas_pt_br;
    }
    return content
  }
}
</script>
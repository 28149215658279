<template>
<div class="artista">
      <div class="artista-responsivo">
      <h3 class="nome">{{artista.nome}}</h3>
      <p class="cidade">{{artista.cidade}}</p>
      <div class="foto">
        <img :src="artista.url_foto" :alt="artista.alt_foto" />
      </div>
      <p class="subtitle-2 texto">{{artista.texto}}</p>
      <div class="space"></div>
      <div class="video" v-html="artista.video">
      </div>  
      <div class="divisor"></div>
      </div>
</div>
</template>

<script>
export default{
    props:['artista']
}
</script>

<style>
  .artista-resposivo{
    width: 100%;
  }
   @media screen and (min-width: 880px) {
    .artista-responsivo{
        width:880px;
    }
  }
  .artista{
        margin: 40px 0px 0px;
    }
  @media screen and (max-width: 1290px) {
    .artista{
        margin-left:24px;margin-right:24px;
    }
  }
    
    .artista .nome{
        margin: 0px 0px 0px;
        font-size:18px;
        color:#000;
        line-height: 24px;
    }
   
    .artista .cidade{
        color:#a41d41;
        font-size: 14px;
        line-height: 24px;
        margin: 0px 0spx 24px;
    }
   

    .artista .foto img{
      float:left;
      width:200px;
      height:200px;
      margin-right:24px;
    }

    @media screen and (max-width: 880px) {
    .artista .foto img{
        float: none;
        display: block;
        }
    }
    .artista .texto{
        color:#000;
        margin:24px 0px 24px;
    }

  
    .video img{
      width:100%;
    }
    .legenda{
      margin: 5px 24px 15px;
    }
    .artista .space{
        clear: both;
        height:25px;
    }
    .artista .divisor{
      margin: 24px 0px;
    }
</style>

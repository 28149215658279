<template>
  <div class="material">
    <Header :lang="lang" :menu="menu" />
    <div class="container mb-300">
      <p class="descricao-equipe">{{intro_equipe}}</p>
      <h5 class="titulo_equipe">{{titulo_equipe}}</h5>
      <p v-html="equipe" class="equipe"></p>
    </div>
    <Footer :lang="lang" :menu="menu" />
  </div>
</template>

<script>
import Header from '../components/Header';
import Footer from '../components/Footer';
var material = {
  pt_br:{
    'intro_equipe':'Para garantir a acessibilidade na  exposição, foi reunida uma equipe multidisciplinar com profissionais de arte, TI, psicologia, design e arquitetura, entre outras áreas. O grupo trabalha desde setembro para criar um modelo em 3D do Espaço Cultural Feevale priorizando a acessibilidade para pessoas com deficiência. Além disso, consultores cegos têm orientado as etapas do trabalho.',
    'titulo_equipe':'participantes',
    'equipe':'<b>Alan Bittencourt</b> - Designer de Experiência do Usuário <br/> '+
    '<b>Alexandra Eckert</b> - Coordenadora do Espaço Cultural Feevale <br>'+
    '<b>André Luiz Marques</b> - Núcleo de Acessibilidade <br/> '+
    '<b>Ariberto de Farias Bauermann Filho</b> - Núcleo de Acessibilidade <br/>'+
    '<b>Bruna Plentz</b> - Núcleo de Arquitetura <br/> '+
    '<b>Bruno Correa Rocha</b> -  Núcleo de Mídias Sociais <br/> '+
    '<b>Carolina Gottert Knies</b> -  Núcleo de Arquitetura <br/> '+
    '<b>Diego Leismann</b> -  Programador <br/> '+
    '<b>Eduarda Londero</b> - Núcleo de Mídias Sociais <br/> '+
    '<b>Fabio A. Cunha</b> - Núcleo de Acessibilidade <br/>'+
    '<b>France Amaral</b> -  Organizadora, Curadora do Educativo e Curadora Internacional <br/> '+
    '<b>Franciele Brandão</b> - Consultora do Núcleo de Acessibilidade <br/> '+
    '<b>Joana Martins Kuhn</b> - Núcleo  Educativo <br/> '+
    '<b>Josiane França</b> - Consultora do Núcleo de Acessibilidade <br/> '+
    '<b>Luiza de Menezes Piffero</b> - Assessora de Imprensa <br/> '+
    '<b>Karine Buglione Campos</b> - Núcleo  educativo <br/> '+
    '<b>Maria Clara Ignácio Leite</b> - Intérprete de libras <br/> '+
    '<b>Marcela Tokiwa Obata dos Santos</b> -  Curadoria e Museografia <br/> '+
    '<b>Mariana Bento Beneti</b> - Tradutora <br/> '+
    '<b>Marina Conpan</b> - Tradutora <br/> '+
    '<b>Paola Virginia Aranda de Magalhães</b> - Colaboradora do Núcleo  de Mídias Sociais <br/> '+
    '<b>Roberto Joaquim da Silva Filho</b> -  Núcleo de Mídias Sociais <br/> '+
    '<b>Patrícia Scherer Bassani</b> - Orientadora <br/> '+
    '<b>Regina de Oliveira Heidrich</b> - Orientadora <br/> '+
    '<b>Sheisa Bittencourt</b> - Organizadora e Curadora do Núcleo de Acessibilidade <br/> '+
    '<b>Suéli Beatriz Lenz</b> - Colaboradora do Núcleo Educativo'
 },
  en:{
    'intro_equipe':'To ensure accessibility at the exhibition, a multidisciplinary team was assembled with professionals from art, IT, psychology, design and architecture, among other areas. The group has been working since September to create a 3D model of the Cultural Space Feevale prioritizing accessibility for people with disabilities. In addition, blind consultants have guided the stages of the work.',
    'titulo_equipe':'Participants',
    'equipe':'<b>Alan Bittencourt</b> - User Experience Designer<br/> '+
    '<b>Alexandra Eckert</b> - Coordinator of Espaço Cultural Feevale <br>'+
    '<b>André Luiz Marques</b> - Accessibility Team <br/> '+
    '<b>Ariberto de Farias Bauermann Filho</b> - Accessibility Team<br/> '+
    '<b>Bruna Plentz</b> -  Architectural Team<br/> '+
    '<b>Bruno Correa Rocha</b> - Social Media Team<br/> '+
    '<b>Carolina Gottert Knies</b> - Architectural Team<br/> '+ 
    '<b>Diego Leismann</b> -  Programmer<br/> '+
    '<b>Eduarda Londero</b> - Social Media Team<br/> '+
    '<b>Fabio A. Cunha</b> -  Accessibility Team <br/>'+
    '<b>France Amaral</b> -  Organizer, Curator of the Educational Team and International Curator<br/> '+
    '<b>Franciele Brandão</b> - Accessibility Team Consultant<br/> '+
    '<b>Joana Martins Kuhn</b> - Educational Team<br/> '+
    '<b>Josiane França</b> - Accessibility Team Consultant<br/> '+
    '<b>Luiza de Menezes Piffero</b> - Press Officer<br/> '+
    '<b>Karine Buglione Campos</b> - Educational Team<br/> '+
    '<b>Maria Clara Ignácio Leite</b> - Brazilian Sign Language Interpreter<br/> '+
    '<b>Marcela Tokiwa Obata dos Santos</b> -  Curatorship and Museography<br/> '+
    '<b>Mariana Bento Beneti</b> - Translator<br/> '+
    '<b>Marina Conpan</b> - Translator<br/> '+
    '<b>Paola Virginia Aranda de Magalhães</b> - Social Media Team<br/> '+
    '<b>Roberto Joaquim da Silva Filho</b> -  Social Media Team<br/> '+
    '<b>Patrícia Scherer Bassani</b> - Advisor<br/> '+
    '<b>Regina de Oliveira Heidrich</b> - Advisor<br/> '+
    '<b>Sheisa Bittencourt</b> - Organizer and Curator of the Accessibility Team<br/> '+
    '<b>Suéli Beatriz Lenz</b> - Educational Team'
  }
}

export default{
  props:['menu','lang'],
  components:{
    Header,
    Footer
  },
  data: function(){
    var content = {}
    if(localStorage.getItem('lang')=='en'){
        content = material.en;
    }else{
        content = material.pt_br;
    }
    return content;
  }
}
</script>
<style>
    .titulo_equipe{
      font-size:16px;
      text-transform: uppercase;
      color:#000;
      font-weight: bold;
    }
    .descricao-equipe{
      font-size: 15px;
      color:#000;
      line-height: 24px;
    }
  .equipe{
    line-height: 40px;
    color:#000;
  }
</style>
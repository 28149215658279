<template>
  <div class="material">
    <Header :lang="lang" :menu="menu" />
    <div class="container mb-300">
      <h2 class="titulo">{{titulo}}</h2>
      <h6 class="subtitulo1">{{subtitulo1}}</h6>
      <p class="texto1">{{texto1}}</p>
      <h6 class="subtitulo2">{{subtitulo2}}</h6>
      <p class="texto2">{{texto2}}</p>
    </div>
    <Footer :lang="lang" :menu="menu" />
  </div>
</template>

<script>
import Header from '../components/Header';
import Footer from '../components/Footer';
var material = {
  pt_br:{
    'titulo':'EM BREVE',
    'subtitulo1':'Material educativo',
    'texto1':'que poderá ser utilizado por professores e educadores de escolas públicas e privadas com o intuito de ampliar a experiência da visitação.', 
    'subtitulo2':'Catálogo', 
    'texto2':'com as obras, biografias e fotografias dos artistas e equipe que participaram da exposição.',
  },
  en:{
    'titulo':'COMING SOON',
    'subtitulo1':'Educational material',
    'texto1':'that can be used by teachers and educators from public and private schools in order to expand the visitation experience.',
    'subtitulo2':'Catalog', 
    'texto2':'with the works, biographies and photographs of the artists and staff who participated in the exhibition.'
  }
}

export default{
  props:['menu','lang'],
  components:{
    Header,
    Footer
  },
  data: function(){
    var content = {}
    if(localStorage.getItem('lang')=='en'){
        content = material.en;
    }else{
        content = material.pt_br;
    }
    return content;
  }
}
</script>
<style>
  .mb-300{
    margin-bottom: 100px;
    padding: 0px 24px;
  }
  @media screen and (min-width: 1290px) {
    .mb-300 {
      padding: 0px 48px;
    }
  }

  .titulo{
    font-size:50px;
    color:#000;
  }
  .subtitulo1, .subtitulo2{
    font-size: 16px;
    color:#a41d41;
    margin: 0px;
    padding: 0px;
  }
  .texto1, .texto2{
    margin:0px;
    margin-bottom:20px;
  }
</style>
<template>
    <div class="header">
        
        <div class="section-header container" id="topo">
        <div class="section-language">
            <div class="text-lang">{{ (lang != 'en') ? 'Language' :'Idioma' }}</div>
            <a href="javascript:;" :class="{'select-lang':true, 'active': (lang != 'en')}" @click="changeLang('pt_br')">Português</a>
            <a href="javascript:;" :class="{'select-lang':true, 'active': (lang == 'en')}" @click="changeLang('en')">English</a>
        </div>
        <div class="section-logo"><img alt="Logo da exposição Presença na ausência" src="../assets/logo-presenca-na-ausencia.png"></div>
        </div>
        <div class="w100">
        <div class="section-menu container" id="nav">
        <router-link v-for="(item_menu, item_key) in menu" :to="item_menu.link" :key="item_key">{{item_menu.name}}</router-link> 
        </div>
        </div>
    </div>
</template>
<script>
    export default{
        props:['lang', 'menu'],
        methods:{
            changeLang(lang){
            localStorage.setItem('lang',lang);
            this.lang = lang
            this.$router.go()
            }
        }
    }
</script>
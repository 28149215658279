<template>
  <div id="app">
    <router-view/>
    </div>
</template>

<script>


export default{
  
}
</script>

<style>

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-ExtraBoldItalic.eot');
    src: url('./assets/fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),
        url('./assets/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-ExtraBold.eot');
    src: url('./assets/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-ExtraBold.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('./assets/fonts/OpenSans-ExtraBold.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-BoldItalic.eot');
    src: url('./assets/fonts/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-BoldItalic.woff') format('woff'),
        url('./assets/fonts/OpenSans-BoldItalic.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-Bold.eot');
    src: url('./assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-Bold.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-Bold.woff') format('woff'),
        url('./assets/fonts/OpenSans-Bold.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: './assets/fonts/Open Sans';
    src: url('./assets/fonts/OpenSans-SemiBold.eot');
    src: url('./assets/fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-SemiBold.woff') format('woff'),
        url('./assets/fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-SemiBoldItalic.eot');
    src: url('./assets/fonts/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-SemiBoldItalic.woff') format('woff'),
        url('./assets/fonts/OpenSans-SemiBoldItalic.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-Regular.eot');
    src: url('./assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-Regular.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-Regular.woff') format('woff'),
        url('./assets/fonts/OpenSans-Regular.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-Italic.eot');
    src: url('./assets/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-Italic.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-Italic.woff') format('woff'),
        url('./assets/fonts/OpenSans-Italic.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-LightItalic.eot');
    src: url('./assets/fonts/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-LightItalic.woff') format('woff'),
        url('./assets/fonts/OpenSans-LightItalic.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('./assets/fonts/OpenSans-Light.eot');
    src: url('./assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('./assets/fonts/OpenSans-Light.woff2') format('woff2'),
        url('./assets/fonts/OpenSans-Light.woff') format('woff'),
        url('./assets/fonts/OpenSans-Light.ttf') format('truetype'),
        url('./assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
html, body{
  margin:0px; padding:0px;
}

#app {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.container{
  width:100%;
  margin: auto;
  text-align: left;
  box-sizing: border-box;
}
@media screen and (min-width: 1290px) {
  .container{
	width:1290px;
  padding:0px 48px;
  box-sizing: border-box;
  margin: 0px auto;
  }
}

.content{
    width:100%;
}
@media screen and (min-width: 700px) {
  .content{
    width:770px;
  }
}
.section-language{
  text-align: right;
  padding: 5px;
  margin-bottom: 20px;
}
.text-lang{
  font-size: 16px;
  color:#a41d41;
  display: inline-block;
  margin-right: 15px;
}
.section-logo{
  margin: 0px 24px 24px;
}
@media screen and (min-width: 1290px) {
  .section-logo{
    margin-left:0px;
  }
}
.w100 {
  overflow: hidden;
  width:100%;
}
@media screen and (min-width: 880px) {
    .w100{overflow: hidden; width:auto;}    
}   
.section-menu{
  margin:4px 0px;
  padding:0px 24px;
}
@media screen and (min-width: 1290px) {
  .section-menu{
    margin-left:auto;
    margin-right:auto;
    padding:0px 48px;
  }
}
#nav a, .select-lang{
  font-family: "Open Sans";
  font-weight: 100;
  color:#666;
  font-size: 16px;
  text-decoration: none;
  margin-right: 18px;
  margin-top: 4px;
  display: inline-block;
}

#nav a.router-link-exact-active, .select-lang.active{
  font-weight: 700;
  color:#000;
  border-bottom: 1px solid #a41d41;
}
.title{
  font-weight:normal;
  line-height: 24px;
  font-size:16px;
  margin:24px;
  color:#000;
}
@media screen and (min-width: 1290px) {
        .title{
        margin-left:0px;
        }
    }
.subtitle{
  font-size:14px;
  line-height: 20px;
  color:#767676;
  margin: 24px;
}
@media screen and (min-width: 1290px) {
        .subtitle{
        margin-left:0px;
        }
    }
.big-title{
  margin: 48px 24px 0px;
  font-size:18px;
  color:#000;
  line-height: 24px;
}
@media screen and (min-width: 1290px) {
        .big-title{
        margin-left:0px;
        }
    }

.color-subtitle,
.color-subtitle a{
  color:#a41d41;
  font-size: 14px;
  line-height: 24px;
  margin: 0px 24px 24px;
}
.color-subtitle a{
  margin-left:0px;
}
@media screen and (min-width: 1290px) {
        .color-subtitle{
        margin-left:0px;
        }
    }
.iframe{
  border:none;
  background-color: #666;
}
.divisor{
  border-bottom: 1px solid #d4d4d4;
  margin: 24px 0px 24px 0px;
  width: 100%;
}
@media screen and (min-width: 880px) {
  .divisor{
  margin: 24px 0px;
  }
}
.subtitle-2{
  font-size:16px;
  line-height: 24px;
  color:#767676;
  margin: 24px;
}
@media screen and (min-width: 1290px) {
        .subtitle-2{
        margin-left:0px;
        }
    }
.section-footer{
  background-color: #a41d41;  
  color:#fff;
}
.footer-menu{
  text-align: left;
  float: left;
  margin: 24px;
  margin-right: 0px;
}
.footer-menu a{
  color:#fff;
  display: block;
  text-decoration:none;
}
.footer-right{
  text-align: right;
  float: right;
  margin: 24px;
}
@media screen and (min-width: 1290px) {
        .footer-right{
        margin-left:0px;
        }
}
.clear{
  clear: both;
}
.section-footer-logo{
  padding: 24px;
  text-align: left;
  font-size: 14px;
  color:#767676;
}
.footer-logo{
  width:auto;
  height:56px;
  margin: 0px;
  vertical-align:middle;
}

.footer-parceiro{
  width:auto;
  height:80px;
  margin: 0px;
  vertical-align:top;
  margin-left:25px;
}

.contato-footer {
  height: 60px;
  margin: 0 24px 24px 24px;
  font-size: 14px;
}
.contato-footer a {
  color: #FFFFFF;
}
</style>
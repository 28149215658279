var artistas_pt_br = {
    intro:"Os artistas escreveram um breve biografia e um vídeo de apresentação falando sobre suas obras que participam da exposição.",
    artistas:[{
      nome:"Ananda Kuhn",
      cidade:"Porto Alegre, RS, Brasil, 1984.",
      url_foto:"/artista/1_Ananda_Kuhn.jpg",
      alt_foto:"A artista está posicionada quase no centro da imagem, com o corpo um pouco mais para a direita. Está em um ambiente fechado de luz artificial. Na imagem podemos ver seu corpo a partir do quadril. Mulher jovem, tem os cabelos curtos e ondulados, possui franja repicada sobre o rosto. Tem os olhos claros e o semblante imponente. Veste calça jeans e blazer verde jade como sobreposição de uma blusa preta. Possui um lenço preto no bolso esquerdo do casaco. Está com a mão esquerda em um dos bolsos, e encontra-se parada de pé em frente a uma obra de arte abstrata, confeccionada com riscos fortes na cor preta sobre a tela branca. A obra atrás da artista ocupa a totalidade da fotografia.",
      texto:" Bacharel em Artes Visuais pelo Instituto de Artes "+
      "da UFRGS, com um ano de Mobilidade Acadêmica na Faculdade de "+
      "Belas Artes da Universidade de Lisboa/PT, também frequentou a "+
      "faculdade de Arquitetura da UFRGS. Nos últimos treze anos reuniu "+
      "mais de 60 mostras no currículo, entre coletivas e individuais, "+
      "nacionais e internacionais, salões e prêmios (incluindo dois "+
      "Açorianos de Artes Plásticas) e diversas produções culturais, "+
      "tanto oficiais quanto independentes. É representada, desde 2013, " +
      "pela Galeria Bolsa de Arte (Porto Alegre/RS e São Paulo/SP). Vive "+
      "atualmente em Bristol, uma cidade super cultural da Inglaterra, "+
      "pintando e tocando bateria no duo de rock/garage AlterModerns.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/FyDZkLhY2Zc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Ariadne Decker",
      cidade:"Niterói, RJ, Brasil, 1960",
      url_foto:"/artista/2_Ariadne_Decker.jpg",
      alt_foto:"A artista está posicionada em frente a uma parede lisa cor laranja. Na imagem podemos visualizar somente seu peito, ombros e cabeça. Possui os cabelos longos, loiros e ondulados, que estão caídos sobre o ombro esquerdo. Tem os olhos azuis e sorriso marcante, sendo possível visualizar os dentes superiores. Usa brinco médio na orelha direita e colar de festa prateado em formato geométrico bastante reluzente. Veste blusa/vestido preto, com decote discreto e alça grossa que sobe pela lateral direita do tronco.",
      texto:"Estudou na FEEVALE, em Novo Hamburgo, graduando-se em Artes "+
      "Plásticas  e cursando pós-graduação em Poéticas Visuais – Processos "+
      "Híbridos - desenho, pintura e escultura, entre outros cursos. "+
      "Recebeu o prêmio Jovem Cidadã da América do Sul em 1977, o 1º  "+
      "lugar, na categoria Juvenil em 1974, no concurso de pintura " +
      "durante a  SESQUIBRAL, na FENAC / NH e tantos outros durante a  "+
      "sua carreira. Em 1986 realizou sua primeira exposição individual "+
      "em uma galeria  comercial. Faz parte do banco de artistas dos Correios "+
      "brasileiros onde já teve 13  selos lançados. "+
      "Ministrou aulas de desenho e técnicas de pastel na FEEVALE, "+
      "no  Estúdio Arte Integrada em Novo Hamburgo durante 15 anos "+
      "e no MARGS – Museu de Arte do Rio Grande do Sul em Porto "+
      "Alegre por 4 anos. Desde 2008,  ministra aulas de desenho, "+ 
      "técnicas de pastel e pintura acrílica em seu atelier. "+ 
      "Tem suas obras reproduzidas em 6 capas de guias telefônicos, "+
      "11 caixas de sapatos exclusivas, diversos cartões postais, "+
      "capas de livros,  revistas, calendários, sapatos, bolsas etc. "+
      "Já expôs seus quadros em inúmeras cidades gaúchas; em Santa "+
      "Catarina, Paraná, São Paulo e Rio de Janeiro; no exterior, "+
      "por 6 vezes em  cidades de Portugal,2 vezes na Espanha e 2 "+
      "nos Estados Unidos. Foi desenhista do suplemento infantil do "+
      "Jornal NH - POPINHA (1989 a 2015). É Ilustradora de "+
      "diversos livros de educação ambiental. Ilustrou 8 cartões "+
      "postais sobre Hamburgo Velho(1993), o mapa da "+
      "Hamburgerbergfest (2019) com imagens das casas históricas e os "+
      "livros de  colorir O Caminho das Cores- Novo Hamburgo (2015) e "+
      "Morro Reuter- de  Todas as Cores (2018).",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/HRm4fYu7Ikk" frameborder="0" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      }, 
      {
      nome:"Carlos Donaduzzi",
      cidade:"Santa Maria, RS, Brasil, 1989.",
      url_foto:"/artista/3_Carlos_Donaduzzi.jpg",
      alt_foto:"O jovem artista está sentado em frente a uma mesa de madeira marrom clara, está em uma sala fechada de luz artificial, na sua lateral esquerda possui uma janela de vidro onde podemos ver de forma desfocada a natureza na rua. No seu fundo possui uma parede branca bastante iluminada e alguns objetos semelhantes a sofás. O artista está posicionado centralmente na fotografia e usa jaqueta jeans e camiseta preta. Tem o braço esquerdo posicionado sobre a barra da janela. Possui cabelos curtos, lisos e de cor escura. Possui a pele clara, os olhos escuros bem marcantes e barba rala. Tem um semblante discreto e está olhando para a câmera.",
      texto:"Artista Visual. Doutorando em Artes Visuais na Universidade "+
      "Federal do Rio Grande do Sul – UFRGS e Mestre em Arte e Tecnologia "+ 
      "pela Universidade Federal de Santa Maria – UFSM. Desenvolve pesquisa "+
      "prática e teórica em fotografia desde 2012. Seu projeto artístico "+
      "articula referências de imagens do cotidiano e a da história da "+
      "arte para discutir através da fotografia o paradoxo real/virtual.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/EQbcLE1lbnw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Chana de Moura",
      cidade:"Estância Velha, RS, Brasil, 1989.",
      url_foto:"/artista/4_Chana_de_Moura.jpg",
      alt_foto:"A artista está situada em meio a natureza, recebendo a luz do sol em seu rosto. No fundo da imagem podemos ver uma área natural, onde na sua esquerda se encontra um monte feito de rochas e na parte direita as rochas se misturam com a vegetação. A artista está à frente da imagem, com os olhos fechados e a cabeça inclinada para o céu. Mantém os braços abertos estendidos em sentido ao solo. Em seu rosto é iluminado pela claridade do sol e seu semblante é sereno. Usa um turbante preto de veludo e tem os cabelos loiros e liso de tom claro, o comprimento fica à altura do peito. Usa um vestido de mangas compridas, colado ao corpo, de estampa floral sobre um fundo preto.",
      texto:"é artista visual, "+
      "educadora e pesquisadora. É bacharel em Artes Visuais pela "+
      "Universidade Federal do Rio Grande do Sul e Mestre pela "+
      "Universidade do Porto, Portugal.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/f8txuzsR8wA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"José Maçãs de Carvalho",
      cidade:"Anadia, Portugal, 1960.",
      url_foto:"/artista/5_Jose_Macas.jpg",
      alt_foto:"A foto representa o rosto do artista em sua totalidade. Aparenta estar ao ar livre, tem os cabelos médios de coloração escura e um pouco grisalhos. Está olhando para câmera e tem um sorriso leve. Usa óculos de grau com moldura retangular de armação fina. Tem a pele clara e as sobrancelhas marcante. Veste camisa polo da cor preta, na qual só visualizamos a gola.",
      texto: "Estudou Línguas e Literaturas em Coimbra, na década de 80 "+
      "e Gestão de Artes, em Macau, na década de 90, onde viveu e "+
      "trabalhou. Em 2014 doutorou-se em Arte Contemporânea, no "+
      "Colégio das Artes da Universidade de Coimbra. É professor "+
      "no Departamento de Arquitetura da Universidade de Coimbra "+
      "e no Colégio das Artes. Expõe fotografia desde 1990 e "+
      "vídeo desde os anos 2000.Nomeado para o prémio BESPhoto "+
      "2005 (2006, CCB, Lisboa) e para a short-list do prémio "+
      "de fotografia Pictet Prix, na Suiça, em 2008. Tem obras "+
      "em coleções privadas e públicas. Expõe fotografia desde "+
      "1990 e vídeo desde os anos 2000. Em 2013 mostrou vídeos "+
      "em Nova Iorque e Paris (Fuso NY, Union Square Park, e "+
      "Chantiers d´Europe, Theatre de la Ville) e em 2011 em Oslo "+
      "(When a painting moves...something must be rotten!, "+
      "Stenersen Museum). Entre 2011 e 2017 realizou 7  "+
      "exposições individuais em torno do tema da sua "+
      "tese de doutoramento (arquivo e memória), no CAV, "+
      "Coimbra; Ateliers Concorde, Lisboa e Colégio das "+
      "Artes, Coimbra; Galeria VPF, Lisboa; Arquivo Municipal "+
      "de Fotografia, Lisboa, Museu Nacional de Arte "+
      "Contemporânea, Lisboa e MAAT, Fundação EDP, Lisboa "+
      "e foi editado o livro Unpacking: a desire for the "+
      "archive (Stolenbooks, Lisboa) em Março de 2014. "+
      "Em 2015 foi publicado o livro: Partir por todos os dias, na Amieira Livros.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/nlZwRBMfNYY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  },

      {
      nome:"Leandro Selister",
      cidade:"Vacaria, RS, Brasil, 1965.",
      url_foto:"/artista/6_Leandro_Selister.jpg",
      alt_foto:"O artista está localizado dentro de um ambiente fechado, aparentando ser uma sala de estar ou biblioteca. Ao seu fundo se encontra uma estante branca de prateleiras altas, onde podemos notar diversos livros e uma quantidade considerável de adornos. Entre eles estão bonecos de personagens do cinema e animações. Na lateral direita da estante temos uma imagem abstrata em uma moldura envidraçada. Na outra lateral possui um espelho que cobre toda a parede ao lado da estante. O artista está vestindo um casaco verde limão por cima de uma camiseta preta. Tem barbas e cabelos grisalhos e usa óculos de grau com armação de metal.",
      texto:"Artista visual com formação em fotografia pelo IA da UFRGS. "+
      "Já realizou diversas exposições individuais, participou de mostras "+
      "coletivas e recebeu diversas premiações em vários salões e editais "+
      "de arte, fotografia e design. Publicou três livros: Cotidiano, "+
      "Intervenções na Trensurb em Porto Alegre, Tique-taque, tremor das "+
      "pequenas coisas e Há tempo atento ao tempo financiado pelo "+
      "FUMPROARTE em 2010. Desde 2013 dedica-se a pesquisa e produção "+
      "de imagens exclusivamente com smartphones e já conta com "+
      "participações e premiações em exposições nacionais e "+
      "internacionais especificamente nesta área. Vive e "+
      "trabalha em Porto Alegre em escritório próprio",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/Gu0bhA-EtLY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Letícia Lampert",
      cidade:" Porto Alegre, RS, Brasil, 1978.",
      url_foto:"/artista/7_Leticia_Lampert.jpg",
      alt_foto:"A artista está ao ar livre em um dia ensolarado. Está posicionada quase centralmente na foto, porém seu corpo está inclinado levemente para o lado direito. Tem os olhos azuis, cabelos loiros, lisos e o comprimento vai até o ombro. Seu semblante é alegre e sorri para a câmera, onde podemos visualizar seus dentes. Veste jaqueta jeans como sobreposição de uma blusa preta com pontos brancos. Usa no pescoço um cordão preto com um medalhão de pedra preta. Ao fundo a natureza está desfocada. Nota-se um borrão de luz colorido, efeito do sol com a lente da câmera, que transpassa seu corpo.",
      texto:"Com formação em Artes Visuais, Design e mestrado "+
      "em Poéticas Visuais (PPGAV-UFRGS), Letícia Lampert vem "+
      "desenvolvendo sua produção principalmente através da fotografia. "+
      "Tem como eixo principal de pesquisa a investigação sobre "+
      "as formas de compreender a paisagem, especialmente urbana, "+
      "e as relações, mediadas pela arquitetura, que estabelecemos "+
      "com as cidades. Teve seu trabalho destacado em salões e "+
      "prêmios tais como o Prêmio Pierre Verger de Fotografia "+
      "(2013) e o Prêmio de Fotografia Chico Albuquerque (2019), "+
      "entre outros. Em 2018, participou da Bienal do Mercosul, "+
      "em Porto Alegre e da Bienal de Fotografia de Beijing, na "+
      "China. Participou de residências artísticas no Brasil, China "+
      "e Taiwan. Publicou até o momento 3 livros: Escala de Cor "+
      "das Coisas (2009), Chai (2016) e Conhecidos de Vista (2018).",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/2UO5bBrinso" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Lisi Wendel",
      cidade:"Crissiumal, RS, Brasil, 1973.",
      url_foto:"/artista/8_Lisi_Wendel.jpg",
      alt_foto:"A artista está posicionada à esquerda da foto, segurando uma obra com a direita e com a mão direita apoiando a cabeça sobre o braço. Seu rosto está virando na vertical direita, onde tem os olhos fixados na obra que está segurando. Usa camiseta básica preta, colares e anéis dourados e uma tiara sobre a cabeça. Está com os cabelos presos e a coloração é escura no topo e clara nas pontas. Usa maquiagem leve e tem as unhas coloridas de lilás. Sua aparência faz um bonito contraste com a obra que segura. Sua imagem ocupa metade da foto. A  obra aparenta ser feita com algum papel de gramatura superior, tom creme. Tem o formato retangular e está virado com vertical. Na metade de baixo há uma pincelada de tinta preta bem simétrica. Na metade superior possui uma imagem que se assemelha a areia da praia pois tem uma aparência arenosa de coloração dourada. A artista está em um ambiente claro, de luz artificial, com paredes brancas, à sua frente alguns trabalhos sobre a ponta de uma mesa.",
      texto:"Lisi Wendel é arquiteta pela UNISINOS desde 1997, "+
      "atuando na arquitetura de interiores desde então. Há 5 "+
      "anos, está a frente da Casa Tendenza Galleria, empresa "+
      "fundada em 2015, onde desenvolve várias coleções "+
      "fotográficas, pinturas e desenhos de técnica mista, "+
      "à mão livre e artes digitais. Assina as coleções da "+
      "Casa Tendenza Galleria e participa de diversas "+
      "exposições como artista contemporânea e suas "+
      "artes estão pelo Brasil, América Latina e Estados "+
      "Unidos. Recentemente participou de sua primeira "+
      "exposição individual com a curadoria da Ana Zavadil.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/_8XQhpXqTDg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Magna Sperb",
      cidade:"Novo Hamburgo, RS, Brasil, 1953.",
      url_foto:"/artista/9_Magna_Sperb.jpg",
      alt_foto:"A artista está posicionada ao centro da foto, tem a cabeça mais inclinada para a esquerda, direção que está no seu olhar. Tem os olhos azuis e os cabelos loiros, lisos e compridos até o alcance dos ombros. Usa maquiagem leve e tem os lábios coloridos com batom rosado. Está com o semblante pensativo. A sua frente, localizada na esquerda do seu rosto há uma escultura feita de ferro com traço abstrato. Ao seu fundo, refletindo na parede do lado direito, podemos ver a sombra da silhueta da artista e da escultura. O reflexo da cabeça da artista na parede é escuro e forma uma composição abstrata junto com o reflexo da escultura de ferro.",
      texto:"É Pós-Graduada em Poéticas Visuais e Processos "+
      "Híbridos (2010), também Pós-Graduada em Artes Visuais "+
      "e Especialização em Cor (1983) e possui Licenciatura "+
      "em Desenho e Plástica (1976), todas pela Feevale. "+
      "Durante anos foi professora de História da Arte e "+
      "Educação Artística e manteve escritório de "+
      "Assessoria em Cor e Pintura para Projetos de "+
      "Arquitetura. Desde 2008 atua somente como artista"+
      "visual e desenvolve sua pesquisa em desenho, "+
      "pintura, fotografia e escultura, tendo já "+
      "participado de mais de 100 exposições coletivas "+
      "no Brasil e exterior. Entre as individuais se "+
      "destacam: PELE E SOMBRA (MARGS – Porto Alegre, "+
      "2019), RECORTES (IAB/RS – Porto Alegre, 2014), "+
      "METAMORFOSE (MAC/RS – Porto Alegre, 2011) e "+
      "(DES) ENCONTROS (Usina do Gasômetro – Porto "+
      "Alegre, 2009).É uma das idealizadoras do MESA"+
      " DE ARTE NA PRAÇA, um projeto de ocupação "+
      "artística contemporânea para a formação de "+
      "público, já na 8°edição.Tem obras nos "+
      "acervos do MARGS, do MAC/RS e do IAB/RS. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/vA68S895l8U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Marcela Novaes",
      cidade:"São Paulo, SP, Brasil, 1994.",
      url_foto:"/artista/10_Marcela_Novaes.jpg",
      alt_foto:"A artista está localizada dentro de uma sala com paredes de pedras, pé direito alto e janelas de vidro e iluminação natural que vem da rua. Na janela esquerda podemos avistar uma diversidade de vegetação, aparentando ser um local bem arborizado, assim como nas paredes de vidro atrás da artista. A sua direita, ao fundo, uma parede comprida de pedra e uma escultura em madeira ao chão, que pela perspectiva ficou localizada próximo ao ombro direito da artista. O rosto da artista, assim como seus ombros ficam à frente da foto. A artista veste casaco preto de veludo e blusa cinza de elastano com gola alta. Seus cabelos possuem coloração avermelhada e tons marrons. São compridos, cacheados e sua franja está jogada para a esquerda. Tem a pele dourada e usa maquiagem leve. Usa óculos de grau de armação grossa, arredondada e de cor semelhante aos seus cabelos. Olha para câmera com olhar firme e semblante imponente.",
      texto:"Artista visual educadora graduada em Artes Visuais pela Universidade Estadual de Londrina  (2016), especialista em Museologia, Colecionismo e Curadoria pelo Centro Universitário Belas  Artes (2017) e em processo de formação em ensino de artes Waldorf na Faculdade Rudolf  Steiner desde 2019. Minha pesquisa como artista envolve a imagem e simbologia da casa em cenas que insinuam  situações, assim como cenas de narrativas incompletas e investigações a partir de noções de  espaços físicos e/ou imaginários, tanto pela cidade e seus mapas, como em projetos instalativos. Atualmente trabalho especialmente explorando a imagem da casa, sua simbologia e situações  (im)possíveis, em desenho/pintura de observação, como também a partir de imagens de casas  encontradas mundo afora em passeios virtuais pelo Google Street View, em busca de situações  e elementos reais (capturadas em dado momento e congeladas na internet até que sejam  substituídas pelas atualizações) e a relação destas casas com a paisagem. Minha vivência estética passa também pelo caminhar, tanto fisicamente como virtualmente, o  que ultimamente tem gerado desdobramentos em escrita. Participei de diferentes exposições coletivas, tanto por edital como a convite, e também de residências artísticas no Brasil. Como artista educadora, trabalho na rede pública municipal de São Paulo, com crianças e  adultos, e a minha principal busca é a de possibilitar o encontro da individualidade e  potencialidades criadora de cada um por meio da experimentação e da consciência do cotidiano  como fonte de experiências estéticas e sensoriais.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/R6zPFnCPoHA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Mariana Corteze",
      cidade:"Sidrolândia, MS, Brasil, 1992.",
      url_foto:"/artista/11_Mariana_Corteze.jpg",
      alt_foto:"A fotografia da artista é uma montagem onde seu rosto aparece dentro de uma moldura vintage de coloração dourada centralizada na imagem. As laterais, por fora da moldura, aparenta uma pintura abstrata com aspecto de nanquim, onde as cores vermelho e azul se misturam formando outras tonalidades. A imagem da artista é representada pelo seu rosto, peito e pelos dedos da mão esquerda, onde ela faz o sinal de paz e amor com a mão localizada quase à frente do seu rosto na região do seu queixo. A artista tem os cabelos castanhos, longos e lisos, presos em formato de coque na parte superior da cabeça, possui franja grossa e tem algumas mechas soltas na lateral da cabeça. Usa óculos de moldura vintage, de armação fina e formato redondo. Usa maquiagem leve e batom levemente avermelhado. Veste camisa de botões cor de rosa, com detalhes florais em preto. A mão que se mantém em frente ao rosto está suja de tinta. Ao fundo, na parte superior esquerda, podemos visualizar uma parede preta com algumas escritas feitas em giz similares a um quadro negro. Já na parte superior direita é possível visualizar um desenho abstrato colorido feito sobre papel kraft.",
      texto:"Artista visual, designer gráfica, "+
      "diretora de arte e pesquisadora em/sobre/com "+
      "arte. É formada em Estudos Artísticos pela "+
      "Universidade de Coimbra, Portugal, Artes "+
      "Visuais pela Universidade Federal de "+
      "Pelotas onde também tem mestrado em "+
      "Processos de Criação e Poéticas do Cotidiano. "+ 
      "Atualmente transita em variadas linguagens "+
      "como colagem, pintura, gravura, ilustração "+
      "digital, desenho e animação. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/o_UB9qUBaGM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Martina Berger",
      cidade:"Portão, RS, Brasil, 1996.",
      url_foto:"/artista/12_Martina_Berger.jpg",
      alt_foto:"A artista está posicionada em frente a uma parede branca e lisa, em uma sala de luz artificial. Encontra-se no centro da foto onde podemos enxergar apenas seu peito e cabeça. Tem os cabelos loiros, lisos e longos que cobrem seu ombro direito. Veste uma blusa com estampa abstrata bastante colorida, que cobre o seu peito, mas deixa os braços a mostra. Usa maquiagem leve e faz um sorriso calmo enquanto olha para câmera. Os lábios estão coloridos de batom avermelhado que faz um bonito contraste com a blusa. Tem pele rosada e semblante feliz.",
      texto:"Graduanda em Bacharelado Artes Visuais – "+
      "Universidade Feevale, Novo Hamburgo/ RS, Em 2015, "+
      "atuou enquanto estudante universitária, como bolsista "+
      "Cnpq de Iniciação Científica no projeto de pesquisa "+
      "da Universidade Feevale intitulado Imagem e Texto: "+
      "Inscrições e grafias em produções poéticas."+
      "Principais exposições coletivas: XVIII Salão de "+
      "Artes Visuais, Pinacoteca Feevale/ Novo Hamburgo, "+
      "2015; Mostra Sem Censura, Nacasa – Coletivo Artístico/ "+
      "Florianópolis, 2017; Finland 100 years of independency, "+
      "Mainlibrary Arkki - Kehätie 4/ Pälkäne/ Finlândia, 2017; "+
      "Mostra Latino-Americana de Gravura, Galeria Boulevard/ "+
      "Londrina, 2018; VI Mostra de Pequenos Formatos – "+
      "Transparência na Arte, Espaço Cultural Chico Lisboa/ "+
      "Porto Alegre, 2018; Ex-Libris - Marca de uma Identidade, "+
      "14th Annual PhotoNOLA Festival, Smith "+
      "Gallery/ New Orleans, 2019. Exposição individual: "+
      "Infância Instantânea, Centro Cultural José Pedro "+
      "Boéssio/ São Leopoldo, 2018.Premiada no XVIII "+
      "Salão de Artes Visuais em 2015.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/lcyXhIvFKL0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Milton Schaefer",
      cidade:"Nova Petrópolis, RS, Brasil, 1960",
      url_foto:"/artista/13_Milton_Schaefer.jpg",
      alt_foto:"O artista está localizado ao centro da imagem, em posição de quem segura a câmera para fazer uma selfie, pois seu braço esquerdo parece estar à frente segurando a câmera. Na imagem podemos visualizar seu peito, ombros e cabeça. A luz do ambiente é natural e ilumina o lado direito do rosto do artista, este que sorri alegremente para foto mostrando seus dentes dianteiros superiores. Usa camiseta branca básica e óculos escuros de armação esportiva, lentes escuras e pretas. Na lente direita reflete a janela para a rua. Na parede atrás do artista podemos ver algumas telas, no lado direito notamos a ponta de três quadros onde não podemos identificar o desenho. Já no lado esquerdo, bem atrás de sua cabeça há uma tela de tamanho grande onde apenas uma parte do desenho pode ser visualizada, sendo um desenho abstrato.",
      texto:"Artista expressionista, atento à sua consciência, "+
      "e estudioso dos valores que transcendem a matéria, sabe "+
      "para o que veio e se entrega com desprendimento às "+
      "inspirações cósmicas, com pinceladas expressivas, "+
      "impetuosas e cores incandescentes traduzindo com a "+
      "emoção da pasta para a tela o seu lirismo, ou como "+
      "ele mesmo gosta de definir: SEU SONHO INTERIOR. Fez "+
      "várias exposições individuais e coletivas no Brasil, "+
      "América Latina e Europa, inclusive através do Projeto "+
      "Enartes. Atualmente mantém atelier em Picada Café -RS, "+
      "na Rota Romântica onde também dispõe de uma galeria "+
      "de arte onde expõe suas obras",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/8ISzAH96_xU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Mitti Mendonça",
      cidade:"São Leopoldo, RS, Brasil, 1990.",
      url_foto:"/artista/14_Mitti_Mendonca.jpg",
      alt_foto:"A artista está posando para foto em um ambiente fechado de paredes brancas e lisas. A luz artificial ilumina seu rosto. Está posicionada ao centro da imagem e tem o corpo curvado para o lado esquerdo. Veste blusa preta de alças grossas e mangas curtas. Usa brinco no formato de argola de tamanho grande e um piercing do mesmo formato no nariz.  Mantém o olhar dirigido para a câmera e sorri discretamente. Tem os cabelos trançados e compridos presos no alto da cabeça, caindo pela lateral direita do seu rosto. Tem a pele negra e olhar marcante. A foto tem coloração branca e preta.",
      texto:"Mitti Mendonça (1990-) é artista têxtil e "+
      "ilustradora. Em 2017, criou o selo Mão Negra Resiste, "+
      "para alinhavar o bordado, que circula há quase 100 "+
      "anos entre as mulheres da família. Usufrui das "+
      "técnicas desenho, bordado e arte digital. Seus "+
      "trabalhos abordam as poéticas negras, a memória, "+
      "o afeto e ancestralidade. Atua nos circuitos de "+
      "Feiras de Arte Impressa e de exposições. Em "+
      "âmbito nacional, teve trabalhos expostos no "+
      "Goethe-Institut e Pinacoteca Ruben Berta "+
      "(Porto Alegre), Valongo Festival Internacional"+
      "da Imagem (Santos-SP) e, no exterior, na Casa "+
      "do Brasil de Lisboa, Portugal. E em 2020, "+
      "venceu o 2º lugar do Prêmio Aliança Francesa "+
      "de Arte Contemporânea. É natural de São "+
      "Leopoldo-RS, onde reside e tem seu ateliê. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/Ac4nDQwBpHI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Paula Ruszkowski",
      cidade:"Camaquã, RS, Brasil, 1994.",
      url_foto:"/artista/15_Paula_Ruszkowski.png",
      alt_foto:"A artista está posicionada à direita da foto, está de costas para a imagem com o seu ombro esquerdo levemente inclinado para trás e rosto virado para câmera. Na imagem podemos visualizar a totalidade do tronco da artista, sendo possível enxergar toda suas costas. Usa blusa marrom de estampa animal, similar às manchas de uma onça. Seus cabelos que aparecem na metade de cima da foto são coloridos de azul e vermelho. São lisos, de comprimento médio e franja curta. A artista usa maquiagem marcante, com olhos delineados e batom escuro. Usa piercing de argola no septo do nariz. Tem um semblante imponente. Na mão direita, localizada próximo ao ombro esquerdo, segura uma taça de vidro cheia de espumante. Na parede atrás da artista podemos ver algumas manchas coloridas, como se fosse uma pintura onde a tinta escorreu por excesso. O ambiente é fechado e de luz artificial.",
      texto:"Graduada em Artes Visuais Bacharelado pela Universidade"+
      "Feevale situada em Novo Hamburgo-Rs. Trabalha como tatuadora "+
      "na cidade de Igrejinha. Seus trabalhos remetem a gestos "+
      "gráficos-pictóricos, provenientes da linguagem da tatuagem. "+
      "Premiada no 20º Salão de Artes Plásticas com Menção Honrosa, "+
      "realizada na Câmara Municipal de Porto Alegre, com a série O "+
      "Passado da Tinta. Já expos seus trabalhos na galeria Chico "+
      "Lisboa - Porto Alegre, Pinacoteca da Universidade Feevale - "+
      "Novo Hamburgo, Museu dos Direitos Humanos do Mercosul- "+
      "Porto Alegre, Galeria Modernidade Novo Hamburgo, Casa de "+
      "Cultura Mario Quintana- Porto Alegre, entre outros.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/4e5fAv_yVyg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Pedro Santos",
      cidade:"Coimbra, Portugal, 1996.",
      url_foto:"/artista/16_Pedro_Santos.png",
      alt_foto:"O artista está posicionado em um ambiente ao ar livre. Na imagem podemos visualizar apenas seu pescoço e cabeça, sendo seu rosto centralizado na foto. Sua cabeça está inclinada levemente para direita. Veste casaco de moletom com capuz como sobreposição de blusa básica. Usa óculos de grau de armação retangular grossa. Tem os cabelos médios e lisos de cor escura, assim como barba e bigode. Tem o semblante pensativo e o olhar vago. Ao fundo da imagem, de forma desfocada podemos ver a natureza. A foto é uma imagem preta e branca.",
      texto:"Pedro Santos é um jovem realizador-argumentista "+
      "com várias curtas-metragens produzidas. Já trabalhou no "+
      "Vietnam, Myanmar e Inglaterra e está agora a tentar produzir "+
      "de forma independente uma série Piranhas Assassinas sobre um "+ 
      "grupo de ativistas motoqueiras que combatem o crime no Brasil. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/ne-OEfKNWag" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Piper Maru Anderson-Klotz",
      cidade:"Vancouver, BC, Canada 1994.",
      url_foto:"/artista/17_Piper_Maru.jpg",
      alt_foto:"A artista está posicionada em frente a uma parede de cor branca e lisa. O ambiente é fechado, de luz artificial e luminosidade boa. Centralizada na foto, a artista está com o tronco um pouco de lado, com o ombro direito mais à frente. Veste blusa de babado branco, com estampa floral feita em relevo e ombros bufantes. O decote é discreto e mostra a região do peito perto do pescoço. O rosto está localizado bem no centro da foto e tem o olhar firme para câmera. Tem os olhos grandes e azuis, a pele rosada e usa maquiagem leve e brilho nos lábios. Usa brincos em formatos de argola de tamanho médio. Os cabelos são loiros e lisos e estão presos, podendo ser possível a visualização somente da sua franja",
      texto:"Piper Maru é uma designer e artista visual que mora em Londres. Ela é graduada pela Royal Central School of Speech and Drama com bacharel (Hons) em Prática Teatral. Tendo se formado em belas artes e design de teatro, seu trabalho transcende uma infinidade de mídias e práticas – com cada disciplina influenciando a outra. Atualmente, ela trabalha como artista freelancer, ilustradora e designer de capa de livro, assim como designer de produção e diretora de arte em teatros e eventos imersivos.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/ftjjOphf-WY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Rochele Zandavalli",
      cidade:"Garibaldi, RS, Brasil, 1980.",
      url_foto:"/artista/18_Rochele_Zandavalli.jpg",
      alt_foto:"A artista sobre um ambiente fechado, de pé e com o corpo apoiado sobre uma parede de azulejos com estampa retrô, na imagem podemos visualizar seu tronco na totalidade. Mantém o corpo inclinado um pouco para direita, onde apoia a cabeça na parede. Seus olhos estão virados para a mesma direção e possui um olhar vago e semblante pensativo. Tem os cabelos loiros, lisos, de tamanho médio. Veste blusa preta de tecido brilhante e mangas médias como sobreposição de uma blusa preta de alça curta. Podemos notar a luz natural que ilumina a cena entrando pelo lado direito da foto.",
      texto:"Rochele Zandavalli é artista e trabalha com fotografia "+
      "experimental associada a diversas técnicas e suportes. Mestre "+
      "em Poéticas Visuais pelo PPGAV do Instituto de Artes da UFRGS, "+
      "graduada em Artes pelo mesmo Instituto. Sua produção em fotografia "+
      "destaca-se pelo investimento na materialidade do suporte e na "+
      "coexistência entre tecnologias. Seus trabalhos incluem desenhos, "+
      "pinturas, gravuras, e produções na área da animação e cinema. "+
      "Tem recebido destaque em Festivais internacionais como o "+
      "Festival de Fotografia de Tiradentes, MG/Brasil; Valongo "+
      "Festival da Imagem, SP/Brasil; Encontros da Imagem, Portugal; "+
      "Outono Fotográfico, Espanha; Paraty em Foco, RJ/Brasil. "+
      "Possui obras na Coleção Joaquim Paiva de fotografia em "+
      "comodato com o MAM/RJ, na Coleção Pierre Bessard, "+
      "Paris, nos acervos da Fundação Vera Chaves Barcellos, "+
      "Museu de Artes do Rio Grande do Sul – MARGS, e Museu "+
      "de Arte Contemporânea MAC/RS. É professora no curso de "+
      "Fotografia na Unisinos. Fotógrafa na UFRGS. Foi "+
      "professora de Extensão do Núcleo de Fotografia da "+
      "UFRGS, e pesquisadora na área de Fotoquímica, no "+
      "mesmo Núcleo. Também ministra cursos na Fluxo – "+
      "Escola de Fotografia Expandida. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/Pr9ByjRJfaI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Silvia Rodrigues",
      cidade:"Itatí, RS, Brasil, 1970.",
      url_foto:"/artista/19_Silvia_Rodrigues.jpg",
      alt_foto:"A artista está sobre um ambiente fechado de iluminação artificial e luz branca. Está de pé, posicionada à frente de uma parede branca onde podemos visualizar um quadro com desenho abstrato como se fosse veias do corpo humano. O corpo da artista está centralizado na foto e ela está de lado, com as costas viradas para a direita. Tem seu ombro direito um pouco à frente, o que mantém seu corpo curvado para a esquerda. Segura o cotovelo esquerdo com a mão direita e mantém o braço esquerdo estendido. Veste blusa de seda cor bege de mangas compridas e bolsos sobre o peito e calças verde militar de alfaiataria, totalizando um estilo casual e elegante. Tem os cabelos loiros, lisos, de tamanho médio. Usa óculos de grau de armação preta, levemente arredondada e grossa, usa brincos prateados de tamanho grande que ficam visíveis somente no lado esquerdo. Usa maquiagem leve e sorri para foto, possui um semblante alegre.",
      texto:"Artista visual, com formação em Artes Visuais na Universidade"+
      "Feevale na cidade de Novo Hamburgo/RS."+
      "Atualmente dedico-me a pintura e escultura "+
      "em atelier próprio na cidade de Esteio/RS.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/9Gt0UvcToNM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Verlisa Navacosta",
      cidade:"Novo Hamburgo, RS, Brasil, 1990.",
      url_foto:"/artista/20_Verlisa_Navacosta.jpg",
      alt_foto:"A artista está posicionada sobre um ambiente fechado de luz artificial. Está centralizada na imagem, onde podemos visualizar seu peito, ombros e cabeça. Ao fundo nota-se uma parede branca lisa, onde à direita da artista vemos um pedaço de uma cortina bege de tecido algodão e do lado esquerdo a ponta de uma mesa e um pedaço de um computador. A jovem artista sorri para a câmera e possui um semblante alegre, tem os cabelos longos e lisos de cor avermelhada, usa batom rosado e maquiagem leve. Veste uma camisa preta de estampa xadrez e detalhes rosa, fechada por botões. Sobre o discreto decote podemos ver um colar prateado com pingente de pedras como se fosse um cristal.",
      texto:"Verlisa Navacosta é graduada em Artes Visuais pela "+
      "Universidade Feevale (2018). Seu trabalho é principalmente "+
      "voltado para a produção fotográfica de fragmentos/detalhes "+
      "presentes nos espaços do cotidiano urbano. Paralelamente, "+
      "atua também como profissional de comunicação.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/QsJ5NrTeGB4" framframebordereborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Victória Kubiaki",
      cidade:"Porto Alegre, RS, Brasil, 1993.",
      url_foto:"/artista/21_Victoria_Kubiki.jpeg",
      alt_foto:"A imagem apresenta somente o peito e rosto da jovem artista, tendo apenas uma parede branca lisa ao seu fundo. O rosto da artista está centralizado, ocupando quase toda a dimensão da imagem. Possui cabelos loiros e lisos, de comprimento médio. As pontas mais volumosas possuem coloração bem clara que se aproxima do branco. A artista tem o semblante sério e olhar imponente. Usa maquiagem marcante, com os olhos delineados e batom vermelho. Está vestindo uma camisa de tecido jeans claro, com decote discreto. Usa colares de cordão marrom e adornos de metal e cristal. Está com o rosto levemente virado para a esquerda. Está em um ambiente fechado de iluminação artificial de tonalidade branca.",
      texto:"Sou uma jornalista e artista multimídia que se dedica "+
      "à produção de conteúdo em múltiplas plataformas. Crio visando "+
      "o conjunto de sensações causados pela estética imagética e "+
      "a escrita criativa. Assim, aplico habilidades técnicas em "+
      "diferentes áreas, sempre em busca da conexão com o próximo e a inovação.", 
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/IMy61Mx7NpY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Zhang Qinzhe",
      cidade:"QingTian, China, 1997.",
      url_foto:"/artista/22_Zhang_Qhinzhe.jpg",
      alt_foto:"O artista está ao ar livre em um ambiente arborizado, onde ao fundo, à direita da imagem podemos visualizar a copa de algumas árvores. O rosto do artista ocupa a parte central da foto, sendo possível visualizar apenas seu pescoço e cabeça. Usa óculos de grau de armação preta, grossa e arredondada. Tem o semblante sério e o olhar imponente. Veste casaco de moletom preto, sendo possível enxergar apenas seu capuz. Tem cabelos castanhos de tamanho médio.",
      texto:"Vive em Portugal há mais de 16 anos, "+
      "licenciado em Multimédia, é um produtor de "+
      "conteúdos audiovisuais sediado no Porto, contando com "+
      "mais de 7 anos de experiência na mesma área. Realizou "+
      "no seu percurso: vídeos institucionais, videoclips, "+
      "spots publicitário, spots de motion graphics, curta "+
      "metragens ficcionais e documentais, colaborou também "+
      "em rodagens de longas metragens.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/-4kxD4kZM5E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
    ]
  }

export default artistas_pt_br;
export default{
    "pt_br":[
        {
            "name":"Exposição",
            "link":"/"
        },
        {
            "name":"Artistas",
            "link":"/artistas"
        },
        {
            "name":"Educativo",
            "link":"/educativo"
        },
        {
            "name":"Equipe",
            "link":"/equipe"
        }
    ],
    "en":[
        {
            "name":"Exposition",
            "link":"/"
        },
        {
            "name":"Artists",
            "link":"/artistas"
        },
        {
            "name":"Educative",
            "link":"/educativo"
        },
        {
            "name":"Team",
            "link":"/equipe"
        }
    ]
}

<template>
  <div class="exposicao">
    <Header :lang="lang" :menu="menu" />  
    <div class="container">
    <div class="content">      
        <p class="title">
            {{exposicao.titulo}}
        </p>
        <p class="subtitle">
            {{exposicao.subtitulo}}
        </p>
        <h2 class="big-title">{{exposicao.titulo_navegue}}</h2>
        <p class="color-subtitle" v-html="exposicao.subtitulo_espaco"></p>
    </div>
    
    <iframe class="iframe" src="/tour-exposicao.html" width="100%" height="515"></iframe>
    
    <div class="content" id="rooms">
        <h2 class="big-title">{{exposicao.titulo_obras}}</h2>
        <p class="color-subtitle">{{exposicao.subtitulo_obras}}</p>
        <p class="subtitle-2">{{exposicao.intro_obras}}</p>
        </div>
    </div>
      
    <Room v-for="room in rooms" :key="room.id" :room="room"  /> 
   
    <Footer :lang="lang" :menu="menu" />  
  </div>
</template>

<script>
import Header from '../components/Header';
import Footer from '../components/Footer';
import Room from '../components/Room';
import exposicao_en from '../content/exposicao_en';
import exposicao_pt_br from '../content/exposicao_pt_br';

export default{
    props:['lang','menu'],
    components:{
        Room,
        Header,
        Footer
    },
    data:function(){
        var content = {}
        if(localStorage.getItem('lang')=='en'){
            content = exposicao_en;
        }else{
            content = exposicao_pt_br;
        }
        return content;
    }
}
</script>
var artistas_en = {
    intro:"Os artistas escreveram um breve biografia e um vídeo de apresentação falando sobre suas obras que participam da exposição.",
    artistas:[{
      nome:"Ananda Kuhn",
      cidade:"Porto Alegre, RS, Brazil, 1984.",
      url_foto:"/artista/1_Ananda_Kuhn.jpg",
      alt_foto:"The artist is positioned almost in the center of the image, with her body a little more to the right. She is in a closed environment with artificial light. In the imager, we can see her body from the hip. Young woman, her hair is short and wavy, her bangs are streaked over her face. She has light eyes and an imposing countenance. She wears jeans and a jade green blazer as an overlay for a black blouse. She has a black scarf in her left coat pocket. She has her left hand in one of her pockets, in finds herself standing in front of an abstract work of art, made with strong streaks in black on the white canvas. The work behind the artist occupies the totality of the photography.",
      texto:"Ananda Kuhn (Porto Alegre/RS, 1984). Graduated in Visual Arts by the UFRGS Institute of Arts, with one year of Academic Mobility at the Faculty of Fine Arts of the University of Lisbon (Portugal), she also attended the Faculty of Architecture at UFRGS. Over the past 13 years, she has brought together more then 60 exhibits in the curriculum, among collective and individual, national and international, salons and awards (including two Azorean Fine Arts) and several cultural productions, both official and independent. Since 2013, she has been represented by Galeria Bolsa de Arte (Porto Alegre – RS and São Paulo – SP). She currently lives in Bristol, a super cultural city in England, painting and playing drums in the rock/garage duo AlterModerns.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/FyDZkLhY2Zc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'  
    },

      {
      nome:"Ariadne Decker",
      cidade:"Niterói, RJ, Brazil, 1960",
      url_foto:"/artista/2_Ariadne_Decker.jpg",
      alt_foto:"the artist is positioned in front of a smooth orange wall. In the image we can see only her chest, shoulders and head. She has long, blond and wavy hair, which is draped over her left shoulder. She has blue eyes and a striking smile, and it is possible to see her upper teeth. She wears a medium earring on her right ear and a silver party necklace in a very shiny geometric shape. She wears a black blouse/dress, with a discreet neckline and thick strap that goes up the right side of the torso.",
      texto:"She studied at FEEVALE, in Novo Hamburgo, graduating in Plastic Arts and post graduating in Visual Poetics – Hybrid Processes – drawing, painting and sculpture, along with other courses. Received the Young Citizen of South America award in 1977, 1st place, in the Youth category in 1974, in the painting competition during SESQUIBRAL, at FENAC /NH and so many others during her career. She participated in the Blue Horse Art Moviments (1974 to 1976), and the Casa Velha – Convívio de Arte (1977 to 1981), she was a member of the conceptual art and performance group called Grupo Valão (together with Flávio Scholles, Rogério Rauber and Mai Bavoso), from the art collectives Grupo ARTE 15 and Project ENARTES (1997 to 2007), among others. Taught drawing and pastel techniques classes at FEEVALE, at the Integrated Art Studio in Novo Hamburgo, for 15 years, and at MARGS (Art Museum of Rio Grande do Sul in Porto Alegre) for 4 years. Since 2008, she teaches drawing classes, pastel techniques and acrylic painting in her studio. Her works are reproduced in 6 phone guide book covers, 11 exclusive shoe boxes, various postcards, book covers, magazines, calendars, shoes, bags, etc. She has already exhibited her paintings in countless cities in the state of Rio Grande do Sul, Santa Catarina, Paraná, São Paulo and Rio de Janeiro; outside of Brazil, by 6 times in different cities of Portugal, 2 times in Spain and 2 times in United States. Participated in the Brazilian delegation at MICSUL 2016, in Bogotá, Colombia.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/HRm4fYu7Ikk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Carlos Donaduzzi",
      cidade:"Santa Maria, RS, Brazil, 1989.",
      url_foto:"/artista/3_Carlos_Donaduzzi.jpg", 
      alt_foto:"the artist is a thin, tall man, with a dark curly hair, he has a light skin and is positioned in front of the camera filming only his face. He is located in a closed room, with white walls and bright artificial light, behind him there is a brown wooden door that is opened, there also is a white frame computer monitor that is located on his right.",
      texto:"Visual Artist. PhD student in Visual Arts the the Federal University of Rio Grande do Sul (UFRGS) and Master in Art and Technology by the Federal University of Santa Maria (UFSM). Develops practical and theoretical research in photography since 2012. His artistic project articulates everyday image references and art history to discuss through photography the paradox of real/virtual.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/EQbcLE1lbnw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Chana de Moura",
      cidade:"Estância Velha, RS, Brazil, 1989.",
      url_foto:"/artista/4_Chana_de_Moura.jpg",
      alt_foto:"the artist is situated in the middle of nature, receiving sunlight on her face. At the bottom of the image we can see a natural area, where on your left is a hill made of rocks and on the right the rocks mix with the vegetation. The artist is in front of the image, with her eyes closed and her head tilted towards the sky. She keeps her arms outstretched, feeling towards the ground. Her face is illuminated by the brightness of the sun and her face is serene. She wears a black velvet turban and has light tone; the length is at her chest heigh. She wears a long-sleeved dress, glued to her body, with a floral print on a black background.",
      texto:"Chana de Moura, Estância Velha 1989, is a visual artist, educator and researcher. She is graduated in Visual Arts by the University of Rio Grande do Sul and holds a Master’s from the University of Porto, Portugal.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/f8txuzsR8wA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"José Maçãs de Carvalho",
      cidade:"Anadia, Portugal, 1960.",
      url_foto:"/artista/5_Jose_Macas.jpg",
      alt_foto:"The photo represents the artist's face in its entirety. He appears to be outdoors, with medium dark hair and a little gray. He is looking at the camera and has a slight smile. He wears prescription glasses with a thin rectangular frame. He has light skin and striking eyebrows. He wears a black polo shirt, in which we only see the collar.",
      texto: "José Maçãs de Carvalho was born in Anadia, in 1960. He studied Languages and Literature in Coimbra, in the 80s, and Arts Management, in Macau, in the 90s, place where he lived and worked. In 2014 he became a PhD in Contemporary Art, at the College of Arts of the University of Coimbra. He is a professor at the Department of Architecture at the University of Coimbra and the College of Arts. He has exhibited photography since 1990 and video since the 2000s. Nominated for the BESPhoto 2005 prize (2006, CCB, Lisbon) and for the short-list of the Pictec Prix photography prize, in Switzerland, in 2008. He has works in private and public collections. He has exhibited photography since 1990 and video since the 2000s. In 2013 he demonstrated videos in New York and Paris (Fuso NY, Union Square Park, and Chantiers dEurope, Theater de la Ville) and in 2011 in Oslo (When a painting moves… something must be rotten!, Sternersen Museum). Between 2011 and 2017 he held seven indivudal exhibitions on the theme of his PhD thesis (archive and memory), at CAV, Coimbra; Concorde Studios, Lisbon and Colégio das Artes, Coimbra; VPF Gallery, Lisbon; Municipal Archive of Contemporary Art, Lisbon and MAAT, EDP Foundation, Lisbon and the book “Unpacking: a desire for the archive” (Stolenbooks, Lisbon), published in March 2014. In 2015 the book Leaving for every day, at Amieira Livros.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/nlZwRBMfNYY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Leandro Selister",
      cidade:"Vacaria, RS, Brasil, 1965.",
      url_foto:"/artista/6_Leandro_Selister.jpg",
      alt_foto:"the artist is located inside of a closed environment, appearing to be a living room or a library. At the background there is a white bookcase with high shelves, where we can see several books and considerable amount of adornments. Among them there are action figures of movie characters and animations. On the right side of the bookcase, we have an abstract image in a glass frame. On the other side, there is a mirror that covers the entire wall next to the bookcase. The artist is wearing a lime green jacket over a black T-shirt. He has gray beards and hair and wear glasses with metal frames.",
      texto:"Visual artist with formation in photography by the IA of the UFRGS. He has already held several solo exhibitions, participated of collective samples exhibitions and received several awards in various salons and edicts of art, photography and design. He has already published three books: “Daily life, Interventions at Trensurb in Porto Alegre”, “Ticking, tremor of the little things” and “There are time attentive to time”, financed by FUMPROARTE in 2010. Since 2013 he has been dedicated to research and production of images exclusively taken with smartphones and already has participations and awards in national and international exhibitions, specifically in this area. He lives and works in Porto Alegre in his own office.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/Gu0bhA-EtLY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Letícia Lampert",
      cidade:" Porto Alegre, RS, Brazil, 1978.",
      url_foto:"/artista/7_Leticia_Lampert.jpg",
      alt_foto:"The artist is outdoors on a sunny day. She is positioned almost centrally in the photo, but her body is tilted slightly to the right. She has blue eyes, blond hair, straight and the length goes up to the shoulder. Her face is cheerful and she smiles at the camera, where we can see her teeth. She wears a denim jacket as an overlay on a black blouse with white dots. She wears a black cord around her neck with a black stone medallion. In the background, nature is blurred. There is a blur of colored light, the effect of the sun with the camera lens, which passes through her body.",
      texto:"With formation in Visual Arts, Design and master degrees in visual poetics (PPGAV-UFRGS), Letícia Lampert has been developing her production mainly through photography. Its main axis of research is the investigation of ways to understand the landscape, especially urban ones, mediated by architecture, that we establish with cities. Her work was highlighted in salons and prizes such as the Pierre Verger Photography Prize (2013) and the Chico Albuquerque Photography Prize (2019), among others. In 2018, she participated in the Mercosul Bienal, in Porto Alegre, and the Beijin Photography Biennial, in China. She participated in artistic residencies in Brazil, China and Taiwan. So far, she has published three books: Scale of colors of things (2009), Chai (2016) and Known by the look (2018).",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/2UO5bBrinso" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Lisi Wendel",
      cidade:"Crissiumal, RS, Brazil, 1973.",
      url_foto:"/artista/8_Lisi_Wendel.jpg",
      alt_foto:" The artist is positioned to the left of the photo, holding a work with her right hand and has her right hand supporting her head on her arm. Her face is turning in the right vertical, where her eyes are fixed on the work she is holding. She wears a basic black T-shirt, gold necklaces and rings and a tiara over her head. Her hair is tied and the color is dark at the top and light at the ends. She wears light makeup and has lilac colored nails. Her appearance makes a beautiful contrast to the work she holds. Her image occupies half of the photo. The work seems to be done with some heavyweight paper, cream tone. It has a rectangular shape and is turned vertically. In the bottom half there is a very symmetrical brushstroke of black paint. The upper half has an image that resembles beach sand as it has a sandy, golden-colored appearance. The artist is in a clear, artificial light environment, with white walls, in front of her some work on the edge of a table.",
      texto:"Lisi Wende has been an architect graduated at Unisinos Since 1997, working in the field of interior architecture since then. For the past five years, she has been in charge of Casa Tendenza Galleria, a company founded in 2015, where she develops several photographic collections, paintings and drawings of mixed techniques, freehand and digital arts. Signs the collections of Casa Tendenza Galleria and participates in several exhibitions as a contemporary artist and her arts are in Brazil, Latin America and the United States. She recently participated in her first solo exhibition curated by Ana Zavadil.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/_8XQhpXqTDg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Magna Sperb",
      cidade:"Novo Hamburgo, RS, Brazil, 1953.",
      url_foto:"/artista/9_Magna_Sperb.jpg",
      alt_foto:"The artist is positioned in the center of the photo, her head is tilted to the left, the direction in which she looks. She has blue eyes and blond hair, straight and long to shoulder length. She wears light makeup and has lips colored with pink lipstick. Her face is thoughtful. In front of her, located on the left of her face, there is a sculpture made of iron with an abstract trace. In the background, reflecting on the wall on the right side, we can see the shadow of the artist's silhouette and sculpture. The reflection of the artist's head on the wall is dark and forms an abstract composition along with the reflection of the iron sculpture.",
      texto:"She is post graduated in Visual Poetics and Hybrid Processes (2010), also has a Postgraduate Degree in Visual Arts and Specialization in Color (1983) ahd has a degree in Design and Plastics (1976), all from Feevale. For years she was a professor of Art History and Artistic Education and Maintained an office of Advisory in Color and Painting for Architecture Projects. Since 2008 she works only as a visual artist and develops her research in drawing, painting, photography and sculpture, she has already participated in more then 100 collective exhibitions in Brazil and abroad. Among the individuals, it stands out: SKIN AND SHADOW (MARGS – Porto Alegre, 2019), CUTOUTS (IAB – RS – Porto Alegre, 2014), METAMORPHOSIS (MAC – RS – Porto Alegre, 2011) and MEETINGS (Usina do Gasômetro – Porto Alegre, 2009). She is one of the creators of the Mesa de arte na praça, a project of contemporary artistic occupation for the formation of audiences, already in the 8th edition. She has works in the collections of MARGS, MAC / RS and IAB / RS.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/vA68S895l8U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Marcela Novaes",
      cidade:"São Paulo, SP, Brazil, 1993.",
      url_foto:"/artista/10_Marcela_Novaes.jpg",
      alt_foto:" the artist is a young woman with long hair and peaked bangs, wearing prescription glasses with a round frame, wearing also a red lipstick and a black blouse with red overlay, with a headset in one ear. She is positioned in front of the camera where we can see her arms and head. In the background, some exhibition posters and some paintings hung in the walls. It is all in a closed environment with a white artificial light.",
      texto:"Visual artist educator graduated in Visual Arts from the State University of Londrina (2016), specialist in Museology, Collecting and Curating at Centro Universitário Belas Artes (2017) and in the process of training in Waldorf arts education at Rudolf College Steiner since 2019. My research as an artist involves the image and symbolism of the house in scenes that imply situations, as well as scenes of incomplete narratives and investigations based on notions of physical and / or imaginary spaces, both by the city and its maps, as well as in installation projects. I currently work especially exploring the image of the house, ts symbology and situations (im)possible, in drawing/painting of observation, such as images of houses found around the world on Google Street View virtual tours. Looking for situations and real elements (captured at a given moment and frozen on the internet until they are replaced by updates) and the relationship of these houses with the landscape. My aesthetic living and experience also involves walking, both physically and virtually, that what lately has generated developments in writing. I participated in different group exhibitions, both by notice and invitation, and also in artistic residencies in Brazil. As an artist and a teacher, I work in the public-school system in São Paulo, with children and adults, and my main quest is to make it possible to meet individuality and creative potential of each one through experimentation and awareness of daily life as a source of aesthetic and sensory experiences.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/R6zPFnCPoHA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Mariana Corteze",
      cidade:"Sidrolândia, MS, Brazil, 1992.",
      url_foto:"/artista/11_Mariana_Corteze.jpg",
      alt_foto:"the artist is young, has long, voluminous hair and wears a brown blouse with wide sleeves. She has light skin and dark eyes. She is sitting in front of the camera in a closed environment of white artificial light. At her back there is a mural that occupies the entire length of the wall in the blackboard style with chalk writing. On the right side of the wall there are some colored images made on kraft paper and pasted on the wall. There also is a white suspended shelf that holds some materials.",
      texto:"Visual artist, graphic designer, art director and researcher on/about/with art. Is graduated in Artistic Studies by the University of Coimbra, Portugal, and Visual Arts by the University of Pelotas, place where she also holds a master’s degree in Creation and Poetics of Daily Life. Nowadays, she transits in several languages such as collage, painting, engraving, digital illustration, drawing and animation.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/o_UB9qUBaGM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'      },
      {
      nome:"Martina Berger",
      cidade:"Portão, RS, Brazil, 1996.",
      url_foto:"/artista/12_Martina_Berger.jpg",
      alt_foto:"the artist has long, straight blond hair. She wears pink lipstick and a black blouse with hollow sleeves. She is positioned in front of the camera at an angle that shows only her shoulders and head. The environment resembles a closed room and is illuminated by artificial light. On the white wall located on her right side, hands a black-framed picture with the image of four women in the cubist style. On her left side we notice the beginning of a red colored wall.",
      texto:"Graduating in Bachelor of Visual Arts – Feevale University, Novo Hamburgo / RS. In 2015, worked as a university student, with a Cnpq scholarship for a Scientific Initiation in the research project of Feevale University entitled Image and Text: Inscriptions and spellings in poetic productions. Mainly collective exhibitions: XVIII Salon of Visual Arts, Pinacoteca Feevale/ Novo Hamburgo, 2015; II Exhibition Ceramic Universe, Hall Monumental da Assembleia Legislativa do Estado de São Paulo/ São Paulo, 2017; 6º Salon Fundarte/Sesc de Arte 10x10, Gallery of Art Loide Schwambach of Fundarte/ Montenegro, 2017; Sample no censorship, Nacasa – Artistic Collective/ Florianópolis, 2017; Finland 100 years of independency, Mainlibrary Arkki - Kehätie 4/ Pälkäne/ Finlândia, 2017; Latin-American Sample of engraving, Gallery Boulevard/ Londrina, 2018; VI Sample of Little Forms – Transparency in Art, Cultural Space Chico Lisboa/ Porto Alegre, 2018; Ex-Libris – Marc of an Identity, Gallery Sesc of Arts Palmas/ Tocantins, 2018; Object-Book, Museo de Arte Cañadense/ Argentina, 2018, IV Three-dimensional of AEERGS, Municipal Center of the Arno Michaelsen Culture/ Gramado, 2019; 14th Annual PhotoNOLA Festival, Smith Gallery/ New Orleans, 2019. Individual exhibition: Instant Childhood, Cultural Center José Pedro Boéssio/ São Leopoldo, 2018. Awarded at the XVIII Visual Arts Exhibition in 2015. Honorable Mention II Exhibition of Ceramic Universe 2017. Participation in the visual essay in Palíndromo magazine of Visual Arts n. 19 UDESC, p. 105-120, 2017.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/lcyXhIvFKL0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Milton Schaefer",
      cidade:"Nova Petrópolis, RS, Brazil, 1960",
      url_foto:"/artista/13_Milton_Schaefer.jpg",
      alt_foto:"the artist is located inside a gallery studio; the environment is closed and has white artificial light. In the background, the walls are decorated with pictures of very colorful abstract paintings. On the floor, there are some screens with the same characteristics. The ceiling in the room is black and has a sharp visible light. Reflectors are positioned in front of the frames. The artist is in the foreground, wears a dark colored shirt, has light skin, strong countenance and thinning hair.",
      texto:"Artist expressionist artist born in Nova Petrópolis. Aware of his conscience and studious of the values that transcend matter, he knows for what he came and surrenders to the cosmic inspirations, with expressive brushstrokes, impetuous and incandescent colors translating the emotion of the paste to his lyricism, or how he likes to define it: HIS INTERIOR DREAM. He made several individual and collective exhibitions in Brazil, Latin America and Europe, including through Enartes Project. Nowadays, he maintains a studio in Picada Café – RS, on the Romantic Route, where he also has an art gallery, where he exhibits his work. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/8ISzAH96_xU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Mitti Mendonça",
      cidade:"São Leopoldo, RS, Brasil, 1990.",
      url_foto:"/artista/14_Mitti_Mendonca.jpg",
      alt_foto:"The artist is located in front of a white wall with no detail, we can only see her shadow that reflects the light. She is young, has braided hair pierced over her left shoulder and is golden brown, her skin is dark and she wears red lipstick. Her eyes are outlined and colored with a reddish shade. She has a nose ring and wear delicate earrings. Wears rope necklace and stone pendant. The camera position shows her shoulders and head.",
      texto:"Mitti Mendonça, 1900, is textile artist and illustrator. In 2017, she created the Mão Negra Resiste (Black Hand Resist) seal, to baste the embroidery, which has been circulating for almost 100 years among the women of the family. Employ the techniques of drawing, embroidery and digital art. Her works address black poetics, memory, affection and ancestry. Operates in the circuits of Printed Art Fairs and Exhibitions. Nationwide, she had work exhibited at the Goethe-Institut and Pinacoteca Ruben Berta (Porto Alegre-RS), Valongo International Image Festival (Santos-SP) and, outside of Brazil, at Casa do Brasil in Lisbon, Portugal. In 3030, she won the 2nd place in the French Alliance of Contemporary Art Award. She is from São Leopoldo-RS, where she lives and has her studio.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/Ac4nDQwBpHI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Paula Ruszkowski",
      cidade:"Camaquã, RS, Brazil, 1994.",
      url_foto:"/artista/15_Paula_Ruszkowski.png", 
      alt_foto:"The artist is located in a room with decorated walls and seated in a brown leather armchair, the back wall has several post-impressionist reference paintings by the painter Van Gogh and pop art posters similar to the French Belle Époque. To the right of the same wall there are some comics with a painting alluding to female body. On the table just below, you would find a blue-green suitcase-style record player on a white table, a disc playing. In the left corner of the image, we notice a small part of a plant. The artist is in the foreground, wearing a pink silk blouse with an animal print, she has long and very colorful hair with color that vary in shades of pink and blue. She wears very striking makeup with highlighted eyes and mouths, the color of her lips is dark red. Also wear a septum piercing.",
      texto:"Graduated in a Bacherlor’s Degree of Visual Arts at the Feevale University located in Novo Hamburgo – RS. She works as a tattoo artist in a city of Igrejinha.Her works refer to a graphic-pictorial gertures, coming from the tattoo language. Awarded at the 20th Plastic Arts Salon with Honorable Mention, held at Porto Alegre City Council, with the Series “The past of the ink”. Already exposed her works at the Chico Lisboa – Porto Alegre Gallery, Feevale University’s Pinacoteca – Novo Hamburgo, Museum of Human Rights of Mercosur – Porto Alegre, Modernidade Gallery – Novo Hamburgo, House of Culture Mario Quintana – Porto Alegre, among others.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/4e5fAv_yVyg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Pedro Santos",
      cidade:"Coimbra, Portugal, 1996.",
      url_foto:"/artista/16_Pedro_Santos.png",
      alt_foto:"The artist is positioned in an outdoor environment. In the image we can see only his neck and head, with his face centered in the photo. His head is tilted slightly to the right. He wears a hooded sweatshirt as a basic blouse overlay. He wears glasses with thick rectangular frames. He has medium dark straight hair, as well as a beard and mustache. He has a thoughtful face and a vague look. In the background of the image, in a blurred way we can see nature. The photo is a black and white image.",
      texto:"Pedro Santos is a young director-writer with several short films produced. He has worked in Vietnam, Myanmar and England and is now trying to independently produce a “Killers Piranhas” series about a group of activists motorcycle racers who fight crime in Brazil.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/ne-OEfKNWag" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Piper Maru Anderson-Klotz",
      cidade:"Vancouver, BC, Canada 1994.",
      url_foto:"/artista/17_Piper_Maru.jpg",
      alt_foto:"The artist is positioned in front of a plain white wall. The environment is closed, with artificial light and good light. Centered on the photo, the artist has her torso slightly to the side, with her right shoulder ahead. She wears a white ruffle blouse, with a floral print made in relief and puffy shoulders. The neckline is discreet and shows the chest area close to the neck. Her face is located right in the center of the photo and has a steady camera eye. She has big blue eyes, pink skin and wears light makeup and lip gloss. She wears earrings in medium-sized hoop shapes. Her hair is blond and straight and is tied, making it possible to see only your bangs.",
      texto:"Piper Maru is a London based designer and visual artist. "+
      "She is a graduate from the Royal Central School of Speech and "+
      "Drama with a BA (Hons) degree in Theatre Practice.  Having "+
      "trained in both fine art and theatre design, her work transcends "+
      "a multitude of media and practices - with each discipline influencing "+
      "the other.  She currently works as a freelance artist, illustrator "+
      "and book cover designer, as well as a production designer and art "+
      "director in immersive theatre and events.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/ftjjOphf-WY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Rochele Zandavalli",
      cidade:"Garibaldi, RS, Brazil, 1980.",
      url_foto:"/artista/18_Rochele_Zandavalli.jpg",
      alt_foto:"The artist is in a closed environment, standing up and with her body supported on a wall of tiles with a retro print, in the image we can see her entire trunk. She keeps her body tilted slightly to the right, where she rests her head against the wall. Her eyes are turned in the same direction and she has a vague and thoughtful look. She has blonde, straight, medium-sized hair. She wears a black blouse of shiny fabric and medium sleeves as an overlay of a black blouse with a short strap. We can notice the natural light that illuminates the scene entering the right side of the photo.",
      texto:"Rochele Zandavalli is an artist and works with experimental photography associated with various techniques and supports. Master in Visual Poetics by PPGAV by Institute of Arts of UFRGS, graduated in Arts at the same Institute. Her production in photography stands out for its investment in the support’s materiality and the coexistence between technologies. Her works include drawing, paintings, prints and production in the area of animation and cinema. She has received prominence in international festivals such as Tiradentes Photography Festival (MG, Brazil), Valongo Image Festival (SP, Brazil), Meetings of Image (Portugal), Photographic Autumn (Spain) and Paraty in Focus (RJ, Brazil). She has works in the Joaquim Paiva Collection of Photography on loan with MAM (RJ), in the Pierre Bessard Colletion (Paris), in the Vera Chaves Barcellos Foundation collections, Rio Grande do Sul Museum of Arts (MARGS), and Museum of Contemporary Art (MAC – RS). She is a teacher in the photography course at Unisinos. Photographer at UFRGS. She was an extension professor at the Photography Center at UFRGS, and researcher in the field of Photochemistry, in the same Nucleus. She also teaches courses at Fluxo – Expanded Photography School. ",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/Pr9ByjRJfaI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Silvia Rodrigues",
      cidade:"Itatí, RS, Brazil, 1970.",
      url_foto:"/artista/19_Silvia_Rodrigues.jpg",
      alt_foto:"The artist is on a closed environment of artificial lighting and white light. She is standing, positioned in front of a white wall where we can see a painting with an abstract design as if it were veins of the human body. The artist's body is centered in the photo and she is on her side, with her back turned to the right. She has his right shoulder slightly forward, which keeps his body curved to the left. She holds her left elbow with her right hand and keep her left arm extended. She wears a beige silk blouse with long sleeves and chest pockets and military green tailoring pants, totaling a casual and elegant style. She has blonde, straight, medium-sized hair. She wears black-framed eyeglasses, slightly rounded and thick, wearing large silver earrings that are visible only on the left side. She wears light makeup and smiles for the photo; she has a cheerful countenance.",
      texto:"Visual artist, with formation in Visual Artist at the Feevale University, in Novo Hamburgo – RS. Nowadays I spend my time painting and sculpting in my own atelier at the city of Esteio – RS Individual exhibitions:  The way of the line, Experimental Gallery, Sapucaia do Sul – RS Collective exhibitions: Body in art: Instrument, Pasticity and support, Contemporary Art Museum of RS, 2015 The view: Traces, Deviations and other Drifts, Contemporary Art Museum of RS, 2015 Uterus Museum and Domesticity: Generations of Feminine at Art, MARGS, 2014 Geography of creation: Art, Fashion and Design, MARGS, 2014 ",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/9Gt0UvcToNM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },

      {
      nome:"Verlisa Navacosta",
      cidade:"Novo Hamburgo, RS, Brazil, 1990.",
      url_foto:"/artista/20_Verlisa_Navacosta.jpg",
      alt_foto:"The artist is seated in front of an office table that contains some personal items, such as a cup and mugs with straws. In the background, there is pot with several colored pencils. Next to it, there is a monitor with two post-it notes, a keyboard and a mouse, in the image of the monitor we can see a photograph of a building with several windows. The artist has long, straight reddish hair, wears red lipstick and light makeup. She is also wearing a black blouse with a low-cut neckline. In the background, on the right side of the image, we notice a refrigerator and some white cabinets with silver handles.",
      texto:"Verlisa Navacosta is graduated in Visual Arts by the Feevale University (2018). Her works is mainly focused on the photographic production of fragments / details presents in daily urban spaces. At the same time, she also works as a communication professional.",
      video:'<iframe width="100%" height="450" src="https://www.youtube.com/embed/QsJ5NrTeGB4" framframebordereborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Victória Kubiaki",
      cidade:"Porto Alegre, RS, Brazil, 1993.",
      url_foto:"/artista/21_Victoria_Kubiki.jpeg",
      alt_foto:"The image shows only the chest and face of the young artist, with only a smooth white wall at the bottom. The artist's face is centered, occupying almost the entire dimension of the image. She has blond and straight hair, of medium length. Her voluminous hair has a very light color in the end that approaches white. The artist has a serious face and an imposing look. Wears striking makeup, with outlined eyes and red lipstick. She is wearing a light denim shirt with a discreet neckline. She wears brown cord necklaces and metal and crystal ornaments. Her face is gently turned to the left. It is in a closed environment of artificial lighting of white tint.",
      texto:"Journalist and a multimedia artist who is dedicated to producing content in multiple platforms. I create aiming at the set of sensations caused by aesthetics imagery and creative writing. So, I apply technical skills in different areas, always in search of connection with others and innovation.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/IMy61Mx7NpY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
      {
      nome:"Zhang Qinzhe",
      cidade:"QingTian, China, 1997.",
      url_foto:"/artista/22_Zhang_Qhinzhe.jpg",
      alt_foto:"The artist is outdoors in a wooded environment, where in the background, to the right of the image, we can see the tops of some trees and the left. The artist's face occupies the central part of the photo, being possible to see only his neck and head. He wears glasses with black, thick and rounded frames. He has a serious and an imposing look. He wears a black sweatshirt, with only his hood being visible. He has thin brown hair.",
      texto:"Born in Chine, have been living in Portugal for more than 16 years, graduated in multimedia, Zhang Qinzhe is a producer of audiovisual products hosted in Porto, counting with more then 7 years of experience in the same area. During his career, he made institutional videos, video clips, advertising spots, motion graphics spots, fictional and documentary shorts films, and also collaborated on feature films.",
      video:'<iframe width="100%" height="450" src="https://www.youtube-nocookie.com/embed/-4kxD4kZM5E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
      },
    ]
  }

export default artistas_en;